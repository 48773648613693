$text-color: #333;
$heading-color: #001f3f;
$link-color: #0074d9;

@mixin container {
  max-width: 1030px;

  margin-left: auto;
  margin-right: auto;

  @media screen AND (max-width: 1030px) {
    width: 95%;
  }
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;

  width: 100%;
  height: 100%;
}

body {
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 400;
  font-family: "Raleway", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  color: $text-color;
}

h1,
h2 {
  margin-top: 0;
  font-family: "Bitter", serif;
  color: $heading-color;
}

h3,
h4,
h5 {
  margin-top: 0;
}

a {
  color: $link-color;

  &:hover {
    color: darken($link-color, 15);
  }
}

img { width: 100%; }

header {
    background-color: #eee;

    & > div {
        @include container();

        display: flex;
        align-items: flex-end;

        img {
            width: 50%;

            @media screen AND (max-width: 810px){
                display: none;
            }
        }

        & > div {
            margin-bottom: 8rem;

            @media screen AND (max-width: 810px){
                margin-top: 1rem;
                margin-bottom: 1rem;
            }

            h1, h2, h3 {
                margin: 0;
            }

            a {
                display: inline-block;
                margin-top: 1.5rem;

                border: 2px solid currentColor;
                padding: .4rem .8rem;
                text-decoration: none;
                border-radius: 4px;
            }
        }
    }
}

nav {
  @media screen AND (max-width: 730px) {
    display: none;
  }

  margin-bottom: 32px;
  border-bottom: 2px solid $link-color;

  > div {
    @include container;

    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 80px;
  }

  h3 {
    font-family: "Bitter", serif;
    color: $heading-color;

    margin-top: 2rem;

    line-height: 1.3;
    letter-spacing: -0.1rem;
    font-size: 2rem;
  }

  ul {
    padding: 0;
    margin: 0;

    list-style: none;

    display: flex;

    li a {
      display: block;
      height: 80px;

      padding: 28px 1.5rem 0 1.5rem;
      border-bottom: 3px solid transparent;

      text-decoration: none;

      &:hover {
        color: white;
        background-color: darken($link-color, 15);
      }
    }
  }
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  background-color: #fff;

  & + main {
    margin-top: 112px;

    section {
        padding-top: 112px;
    }
  }
}

section {
  @include container;

  @media screen AND (max-width: 730px) {
    padding-top: 3rem;
  }

  h2 {
    font-size: 2.6rem;
    margin-bottom: 1.3rem;
  }

  & > div {
    display: flex;
    flex-wrap: wrap;

    p {
      width: 30rem;

      &:first-of-type {
        margin-right: 2rem;
      }
    }

    dl {
      width: 30rem;
      overflow-wrap: break-word;

      dt {
        font-weight: bold;
      }

      dd {
        display: list-item;
        list-style: disc inside;
        margin-left: 0;
      }
    }
  }
}

footer {
    @include container();

    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    border-top: 2px solid $text-color;
}